import React, { useEffect } from 'react'
import {Body, FlexRowWrap, WrapPadding} from '../../common/commonStyle'
import {ProductSection02Wrap, ProductsWrap} from './styles'
import Section01 from "./section/section01";
import Section02Pre from "./section/section02-pre";
import Section03 from "./section/section03";
import Section04 from "./section/section04";
import Section02Mcp from "./section/section02-mcp";
import Sec02 from './section/sec02';
import Sec03 from './section/sec03';

const Products = () => {

  // 타이틀 변경
  useEffect(() => {
    const titleElement = document.getElementsByTagName('title')[0]
    if (titleElement) {
      titleElement.innerHTML = 'Products | 비큐AI'
    }

    return () => {
      titleElement.innerHTML = '비큐AI'
    }
  }, [])

  return (
    <ProductsWrap>
      <WrapPadding />
      <Section01 />
      {/* <ProductSection02Wrap>
        <Section02Pre/>
        <Section02Mcp/>
      </ProductSection02Wrap> */}
      <Sec02 />
      <Sec03 />
      {/* <Section03 />
      <Section04 /> */}
    </ProductsWrap>
  )
}

export default Products
