import React, { useEffect, useState } from 'react'
import { Sec03Wrap } from './styles'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Navigation } from 'swiper/modules'
import { useRecoilValue } from 'recoil'
import { langAtom } from '../../../../store/recoil/lang'

const Sec03 = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  const item = [
    {
      category: 'SaaS',
      title: `실시간 미디어 데이터 모니터링 및 분석 플랫폼`,
      description: lang ? '국내 3,000여 미디어 매체 데이터를 실시간으로 모니터링, 분석, 자동 리포팅, 통계 시각화, 공유 등을 지원하는 플랫폼 입니다.' : 'AI Surfer is a media monitoring platform that provides analysis, reporting, visualizes statistics and shares data from over 3,000 media outlets in real-time.',
      image: '/image/product/SaaS Logo.png',
      link: 'https://www.eyesurfer.com/index.php',
    },
    {
      category: 'SaaS',
      title: '실시간 미디어 데이터 공유 및 뷰어 서비스',
      description: lang ? 'AISURFER를 통해 생성된 다양한 리포트와 뉴스 정보들을 조직 내/외부로 연동하거나 공유하는 뷰어 서비스를 제공합니다.' : 'A viewer service facilitating the internal and external sharing of diverse reports and news monitoring data produced by AI SURFER.',
      image: '/image/product/SaaS Logo2.png',
      link: 'https://intro-vu.aisurfer.com/',
    },
    {
      category: 'SaaS',
      title: `미디어 빅데이터 검색 · 분석 기반 AI기사 생성 Chatbot서비스 플랫폼`,
      description: lang ? 'BECUAI의 자체 AI기술과 빅데이터를 기반으로 작성자 아바타를 생성하여 논조 및 톤앤매너에 맞는 기사를 생성합니다. 생성된 기사와 유사한 관심매체, 기자를 매칭하여 자동배포까지 One-Stop으로 지원합니다.' : `Leveraging BECUAI's core AI technology and big data, we generate articles by creating author personas. We provide one-stop support, connecting you with the most relevant journalists and media outlets for your article, and facilitating automatic distribution.`,
      image: '/image/product/SaaS Logo3.png',
      link: 'https://www.eyesurfer.com/Content/sub01_06.php',
    },
  ]

  const [sliderActive, setSliderActive] = useState(0)

  const [width, setWidth] = useState(0)

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Sec03Wrap>
      <section className='sec03-01'>
        <div className="container">
          <div className="text-box">
            <span className='blue'>SaaS</span>
            {
              sliderActive === 0 ? (
                lang ? (
                  <h2>
                    실시간 미디어 데이터<br className="tablet"/> 모니터링 및 분석 플랫폼
                  </h2>
                ) : (
                  <h2>
                    Real-time Media<br className="tablet"/> Monitoring and<br className="tablet"/> Analysis Solution
                  </h2> 
                )
              ) : sliderActive === 1 ? (
                lang ? (
                  <h2>
                    실시간 미디어 데이터<br className="tablet"/> 공유 및 뷰어 서비스
                  </h2>
                ) : (
                  <h2>
                    Media data reporting<br className="tablet"/> and viewer service
                  </h2>
                )
              ) : (
                lang ? (
                  <h2>
                    미디어 빅데이터 검색 · <br className="tablet"/>분석 기반 AI기사 생성 <br className="tablet"/>Chatbot서비스 플랫폼
                  </h2>
                ) : (
                  width <= 1024 ? (
                    <h2>
                      A chatbot service <br className="tablet"/>generating AI-driven <br className="tablet"/>articles based on <br className="tablet"/>media big data
                    </h2>
                  ) : (
                    <h2>
                      AI Chatbot service for articles with media big-data
                    </h2>
                  )
                )
              )
            }
            <p>{item[sliderActive].description}</p>
            <button
              type='button'
              onClick={() => window.open(item[sliderActive].link, '_blank')}
            >
              {lang ? '더 보기' : 'More'}
            </button>
            <div className={sliderActive === 2 ? "btn-wrap pc b18" : "btn-wrap pc"}>
              <button 
                type='button' 
                className={sliderActive === 0 ? 'product-slider-prev disabled' : 'product-slider-prev'}
              >
                <img src="/image/product/product-arrow.svg" alt="이전버튼" />
              </button>
              <button 
                type='button' 
                className={sliderActive === 2 ? 'product-slider-next disabled' : 'product-slider-next'}
              >
                <img src="/image/product/product-arrow.svg" alt="다음버튼" />
              </button>
            </div>
          </div>
          <div className="logo-box">
            <Swiper
              modules={[Navigation]}
              slidesPerView={1}
              navigation={{
                prevEl: ".product-slider-prev",
                nextEl: ".product-slider-next",
              }}
              onSlideChange={(swiper: any) => setSliderActive(swiper.realIndex)}
            >
              {
                item.map((it, key) => (
                  <SwiperSlide key={key}>
                    <img src={it.image} alt={'로고'} />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
          <div className="btn-wrap tablet">
            <button 
              type='button' 
              className={sliderActive === 0 ? 'product-slider-prev disabled' : 'product-slider-prev'}
            >
              <img src="/image/product/product-arrow.svg" alt="이전버튼" />
            </button>
            <button 
              type='button' 
              className={sliderActive === 2 ? 'product-slider-next disabled' : 'product-slider-next'}
            >
              <img src="/image/product/product-arrow.svg" alt="다음버튼" />
            </button>
          </div>
        </div>
      </section>
      <section className='sec03-02'>
        <div className="container">
          <div className="text-box">
            <span className='red'>B2C New Service</span>
            <h2>
              Open Media <br/>
              Platform ROZEUS
            </h2>
            <p>
              {lang ? '매일 발행되는 수십만건의 뉴스에 AI 및 빅데이터 기술을 적용하여 개인이 원하는 뉴스를 선별하여 볼 수 있도록 제작된 맞춤형 뉴스 서비스입니다. AI 시대에 대중이 신뢰하는 차세대 뉴스 미디어 패러다임을 연구합니다.' : `A personalized news service applied AI technology and Big Data,  enabling users to select and view the news of their preference from millions published daily. We're pioneering the next-generation news media paradigm, aiming for trustworthiness in the AI era.`}
            </p>
            <button
              type='button'
              onClick={() => window.open('https://rozeus.io/')}
            >
              {lang ? '더 보기' : 'More'}
            </button>
          </div>
          <div className="logo-box">
            <img src={'/image/product/ROZEUS.png'} className='w274' alt='ROZEUS' />
          </div>
        </div>
      </section>
    </Sec03Wrap>
  )
}

export default Sec03